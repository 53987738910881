import React, {useEffect, useRef} from 'react';
import './Navbar.css';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ConfettiController from "../../../app/components/ConfettiController";
import {getProfilePhoto} from "../../../../store/userSlice";
const Navbar = () => {

    const confettiRef = useRef(null);

    const handleConfetti = () => {
        if (confettiRef.current) {
            confettiRef.current.startConfetti();
        }
    };

    const storeUser = useSelector((state) => state.user.value);

    const progressWidth = ((storeUser?.exp_current - storeUser?.exp_min)  / (storeUser?.exp_max - storeUser?.exp_min)) * 100 + '%';

    useEffect(() => {

        if (storeUser?.coins > 0 && storeUser.exp_current === storeUser.exp_min) {
            handleConfetti();
        }
    }, [storeUser.exp_current, storeUser?.coins, storeUser.exp_min]);

    const handleClick = () => {
        window.location.href = 'https://api.winplay.tech/winline-redirect';
    }

    return (
        <div className="navbar">
            <ConfettiController ref={confettiRef}/>
            <div className="profile-container">
                <img width="35" height="35" src={getProfilePhoto(storeUser.id, storeUser.photo_ext)} alt={storeUser.first_name} className="user-avatar"/>
                <div className="profile-details">
                    <div className="profile-information">
                        <div className="user-details">
                            <p className="user-name">{`${storeUser.first_name} ${storeUser.last_name}`}</p>
                            {
                                storeUser?.is_winline_linked ? (
                                    <img width="32" height="32" src="/images/winline-mini.png" alt="Winline"/>
                                ) : (
                                    <div onClick={handleClick}>
                                        <img width="33" height="33" src="/images/winline-mini-bw.png" alt="Winline"/>
                                    </div>
                                )
                            }
                        </div>
                        <div className="level-container">
                            <span className="level-text">Lvl {storeUser?.lvl}</span>
                        </div>
                    </div>
                    <div className="user-progress-bar">
                        <div className="progress" style={{width: progressWidth}}></div>
                    </div>
                </div>
                <Link to="/faq" className="info-btn">
                    <img width="20" height="20" src="/images/question.png" alt="faq"/>
                </Link>
            </div>
        </div>
    );
};

export default Navbar;
