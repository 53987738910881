import React, { useCallback, useEffect, useState } from 'react';
import './Leaders.css';
import LayoutMain from "../app/components/layout/LayoutMain";
import { useInitDataRaw } from "../../utils/tg";
import UserList from "./components/userList/UserList";
import TeamList from "./components/teamList/TeamList";
import NavigationBar from "../app/components/navigationBar/NavigationBar";
import { logError } from "../../utils/errorHandler";
import { useDispatch, useSelector } from "react-redux";
import { setLeaders } from "../../store/leadersSlice";
import { getLeaders } from "../../api/leaders";
import UserItem from "./components/userItem/UserItem";
import {getProfilePhoto} from "../../store/userSlice";

const Leaders = () => {
    const initDataRaw = useInitDataRaw();
    const dispatch = useDispatch();
    const handleSetLeaders = useCallback((leaders) => dispatch(setLeaders(leaders)), [dispatch]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const leadersData = useSelector((state) => state.leaders.value);

    useEffect(() => {
        const fetchLeaders = async () => {
            try {
                const response = await getLeaders(initDataRaw);
                handleSetLeaders(response.data);
                if (response.data.teams.length > 0) {
                    setSelectedTeam(response.data.teams[0].team);
                }
            } catch (error) {
                logError(error.message, 'Leaders fetchLeaders', 0, 0, error);
                console.error("Error fetching leaders:", error);
            }
        };
        fetchLeaders();
    }, [handleSetLeaders, initDataRaw]);

    const handleTeamSelect = (team) => {
        setSelectedTeam(team);
    };

    return (
        leadersData.teams &&
        <LayoutMain>
            <div className="leaders">
                <TeamList teams={leadersData.teams} selectedTeam={selectedTeam} onSelectTeam={handleTeamSelect} />
                <div className="counter-value">
                    <img className="coin-icon-main" width="100" height="100" src="/images/coin100.png" alt=""/>
                    <div>{selectedTeam?.coins_total?.toLocaleString() || 0}</div>
                </div>
                <hr />
                <UserList users={leadersData.teams.find(data => data.team.id === selectedTeam?.id)?.players || []} />

                {leadersData.current_player.team_id ===  selectedTeam?.id && (
                    <div className="leaders-me">
                        <UserItem
                            position={leadersData.current_player.place}
                            name="Вы"
                            points={leadersData.current_player.coins}
                            avatar={getProfilePhoto(leadersData.current_player.id,leadersData.current_player.photo_ext)}
                        />
                    </div>
                )}
            </div>
            <NavigationBar />
        </LayoutMain>
    );
};

export default Leaders;
