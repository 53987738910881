const getPercentageColor = (percentage) => {

    const normalizedPercentage = Math.min(100, Math.max(0, percentage));

    const hexToRgb = (hex) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return [r, g, b];
    };

    const redColor = hexToRgb('#D22730');
    const yellowColor = hexToRgb('#FFE764');
    const greenColor = hexToRgb('#4ABA24');

    let targetColors;
    let localPercentage;

    if (normalizedPercentage <= 50) {
        targetColors = [redColor, yellowColor];
        localPercentage = (normalizedPercentage * 2);
    } else {
        targetColors = [yellowColor, greenColor];
        localPercentage = (normalizedPercentage - 50) * 2;
    }

    const result = targetColors[0].map((start, i) => {
        const end = targetColors[1][i];
        return Math.round(start + (end - start) * (localPercentage / 100));
    });

    return `rgb(${result[0]}, ${result[1]}, ${result[2]})`;

};

export default getPercentageColor;