import React, { useState, useEffect } from 'react';
import './Welcome.css';
import {useSelector} from "react-redux";
import {getProfilePhoto} from "../../../../store/userSlice";

const Welcome = ({ onStartGame }) => {
    const [showDescription, setShowDescription] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [visiblePlaceholders, setVisiblePlaceholders] = useState([false, false, false, false]);
    const user = useSelector((state) => state.user.value);

    useEffect(() => {
        const descriptionTimeout = setTimeout(() => setShowDescription(true), 1000);
        const buttonTimeout = setTimeout(() => setShowButton(true), 2000);

        return () => {
            clearTimeout(descriptionTimeout);
            clearTimeout(buttonTimeout);
        };
    }, []);

    useEffect(() => {
        if (showButton) {
            visiblePlaceholders.forEach((_, index) => {
                setTimeout(() => {
                    setVisiblePlaceholders((prev) => {
                        const newPlaceholders = [...prev];
                        newPlaceholders[index] = true;
                        return newPlaceholders;
                    });
                }, 150 * (index + 1));
            });
        }
    }, [showButton, visiblePlaceholders]);

    const handleStartGame = () => {
        if (onStartGame) {
            onStartGame();
        }
    };

    return (

        <div className="app-container">
            <div className="welcome-section fade-in">
                <img width="262" height="70" src="/images/winline.png" alt="Top Logo" className="winline-logo" />
                <h1 className="welcome-title">Привет!</h1>
                <div className="user-info super-border">
                    <img width="160" height="160" src={getProfilePhoto(user.id,user.photo_ext)} alt="User Avatar" className="user-avatar"/>
                    <p className="user-name">{`${user.first_name} ${user.last_name}`}</p>
                </div>
            </div>

            {showDescription && (
                <div className="description-section fade-in">
                    <div className="vertical-squares">
                        <div className="vertical-square" />
                        <div className="vertical-square" />
                    </div>
                    <p className='welcome-question'>Что такое WinPlay?</p>
                    <p className="welcome-description alt-font">
                        Каждый день и месяц мы проводим розыгрыш призов. Шанс победы зависит
                        от заработанных
                        <img width="100" height="100" src="/images/coin100.png" alt="WCoin"/>
                        WinCoin
                    </p>
                </div>
            )}

            {showButton && (
                <div className="action-section fade-in">
                    <div className="vertical-squares">
                        <div className="vertical-square"/>
                        <div className="vertical-square"/>
                    </div>
                    <p className='welcome-question'>Как заработать WinCoin?</p>
                    <div className="placeholder super-border">
                        {['/images/thumbs-up.svg', '/images/calendar-dates.svg', '/images/book-open-pink.svg', '/images/users-orange.svg'].map((src, index) => (
                            <div
                                key={index}
                                className={`placeholder-item ${visiblePlaceholders[index] ? 'visible' : ''}`}
                            >
                                <img width="20" height="20" src={src} alt="Icon"/>
                                <span>
                                    {index === 0 && 'Кликай на персонажа'}
                                    {index === 1 && 'Заходи в игру каждый день'}
                                    {index === 2 && 'Выполняй задания'}
                                    {index === 3 && 'Приглашай друзей'}
                                </span>
                            </div>
                        ))}
                    </div>
                    <button className="start-button" onClick={handleStartGame}>
                        Выбрать команду и начать
                    </button>
                </div>
            )}
        </div>
    );
};

export default Welcome;
