import React, {useState, useRef, useCallback} from 'react';
import './Lottery.css';
import WinResult from '../winResult/WinResult';
import LoseResult from '../loseResult/LoseResult';
import { getResult } from "../../../../api/games";
import { useInitDataRaw } from "../../../../utils/tg";
import { setValue } from "../../../../store/userSlice";
import { useDispatch } from "react-redux";
import {getState} from "../../../../api/user";
import getPercentageColor from "../../../app/components/PercentageColor";
import {logError} from "../../../../utils/errorHandler";

const Lottery = ({ gamesData }) => {

    const initDataRaw = useInitDataRaw();
    const [isSpinning, setIsSpinning] = useState(false);
    const [activeCard, setActiveCard] = useState(null);
    const [result, setResult] = useState(null);
    const dispatch = useDispatch();
    const spinInterval = useRef(null);
    const handleSetUser = useCallback((user) => dispatch(setValue(user)), [dispatch]);

    if (!gamesData || !gamesData.available_lottery) {
        return null;
    }

    const { available_lottery } = gamesData;
    const prizes = Object.keys(available_lottery.prizes);

    const fetchUserState = async () => {
        try {
            const response = await getState(initDataRaw);
            const {state} = response.data;
            handleSetUser(state);
        } catch (error) {
            logError(error.message, 'Lottery fetchUserState', 0, 0, error);
        }
    };

    const startSpinning = () => {
        setIsSpinning(true);
        setActiveCard(null);
        setResult(null);
        let index = 0;

        spinInterval.current = setInterval(() => {
            setActiveCard(index % prizes.length);
            index += 1;
        }, 100);

        const fetchResult = async () => {
            try {
                const response = await getResult(initDataRaw);
                setTimeout(() => {
                    clearInterval(spinInterval.current);
                    setIsSpinning(false);
                    const winningIndex = Math.floor(Math.random() * prizes.length);
                    setActiveCard(winningIndex);
                    setResult(response.data);
                }, 3000);
            } catch (error) {
                console.error('Error fetching lottery result:', error);
                clearInterval(spinInterval.current);
                setIsSpinning(false);
                logError(error.message, 'Lottery fetchResult', 0, 0, error);
            }
        };
        fetchResult();
    };

    if (result) {
        fetchUserState();
        if (result.result) {
            return <WinResult prize={result.result} />;
        } else {
            return <LoseResult />;
        }
    }

    return (
        <div className="available-lottery">
            <div className="available-lottery-title">
                <button className="active">
                    {available_lottery.is_daily ? 'ЕЖЕДНЕВНЫЙ' : 'ЕЖЕМЕСЯЧНЫЙ'}
                </button>
                <h2>Доступен розыгрыш</h2>
                <p>Жми на кнопку, чтобы испытать удачу и выиграть фрибет</p>
            </div>
            <div className="prizes-grid">
                {prizes.map((amount, index) => {
                    const chance = available_lottery.prizes[amount];
                    return (
                        <div
                            className={`prize-card super-border ${isSpinning && index === activeCard ? '--selected' : ''}`}
                            key={index}
                        >
                            <div className="prize-info">
                                <div className="prize-title">
                                    <div className="title">Фрибет</div>
                                    {new Intl.NumberFormat('ru-RU').format(amount)}
                                </div>
                                <img width="63" height="62" src={'/images/prizes/' + amount + '.png'} alt="Фишка" className="prize-icon"/>
                            </div>
                            <div className="prize-chance">
                                <div
                                    className="chance-bar"
                                    style={{
                                        '--bar-color': getPercentageColor(chance),
                                        '--bar-width': `${chance}%`
                                    }}
                                ></div>
                                <p><span className="chance">шанс:</span> {chance.toFixed(2)}%</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            <button
                className={`try-luck-button ${isSpinning ? 'disabled' : ''}`}
                onClick={startSpinning}
                disabled={isSpinning}
            >
                {isSpinning ? 'Испытываем удачу...' : 'Испытать удачу'}
            </button>
        </div>
    );
};

export default Lottery;
