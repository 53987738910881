import React, { useCallback, useEffect } from 'react';
import { initBackButton, useInitDataRaw } from "../../../utils/tg";
import LayoutMain from "../../app/components/layout/LayoutMain";
import { formatDate } from "../../../utils";
import "./Rewards.css"
import WinlineCard from "../../winline/winlineCard/WinlineCard";
import { useDispatch, useSelector } from "react-redux";
import { setRewards } from "../../../store/rewardsSlice";
import { getRewards } from "../../../api/rewards";
import {logError} from "../../../utils/errorHandler";

const Rewards = () => {
    const user = useSelector((state) => state.user.value);
    const rewardsData = useSelector((state) => state.rewards.value);
    const initDataRaw = useInitDataRaw();
    const dispatch = useDispatch();
    const handleSetRewards = useCallback((rewards) => dispatch(setRewards(rewards)), [dispatch]);


    useEffect(() => {
        initBackButton();
    }, []);

    useEffect( () => {
        const fetchRewards = async () => {
            try {
                const response = await getRewards(initDataRaw);
                handleSetRewards(response.data);
            } catch (error) {
                logError(error.message, 'Rewards fetchRewards', 0, 0, error);
                console.error("Error fetching rewards:", error);
            }
        };

        fetchRewards();
    }, [handleSetRewards, initDataRaw]);

    const STATUS_MAPPING = {
        rewarded: 'Фрибет начислен в ЛК Winline',
        error_no_winline_user_id: 'Привяжите аккаунт Winline для начисления приза',
        default: 'Фрибет скоро появится в ЛК Winline',
    }

    return (
        <LayoutMain>
            <div className="rewards">
                <h2>Ваши выигрыши</h2>
                <div className="rewards-list">

                    {rewardsData.rewards.length === 0 && (<div>Здесь будут отображаться ваши выигрыши</div>)}
                    {rewardsData.rewards.map((reward, index) => (
                        <div key={index} className="reward-item">
                            <img width="20" height="20" src="/images/gift-icon.svg" alt="Reward Icon" className="img-chip"/>
                            <div className="reward-content">
                                <div className="reward-info">
                                    <div className="coin-section">
                                        <span className="freebet-amount">Фрибет {reward.amount.toLocaleString()}</span>
                                    </div>
                                </div>
                                <div className="reward-date">Выиграли {formatDate(reward.date)}</div>
                            </div>

                            <div className="reward-status">
                                <div
                                    className={reward.status === 'rewarded' ? '--rewarded' : '--wait'}>{reward.status === 'rewarded' ? 'Начислен' : 'Ожидает начисления'}</div>
                                <div
                                    className="reward-date">{STATUS_MAPPING[reward.status] || STATUS_MAPPING['default']}</div>
                            </div>
                        </div>
                    ))}
                </div>
                {rewardsData.rewards.length >0 &&
                <WinlineCard isWinlineLinked={user.is_winline_linked } text="Привяжите аккаунт Winline, чтобы получить выигранные призы"/>
                }
            </div>
        </LayoutMain>
    );
};

export default Rewards;
