import React from 'react';
import './WinlineCard.css';
import {backButton} from "@telegram-apps/sdk-react";

const WinlineCard = ({ isWinlineLinked, text }) => {

    if (isWinlineLinked){
        return <></>
    }
    const handleClick = () => {
        window.location.href = 'https://api.winplay.tech/winline-redirect';
        backButton.show();
        backButton.onClick(() => {
            window.location.href = 'https://winplay.tech';
        });

    }

    return (
        <div className="winline-card super-border">
            <div className="winline-card-header">
                <img className="winline-logo-big" src="/images/winline.svg" width="300" height="72" alt="Winline Logo" />
                <div className="winline-link-bonus">
                    <img width="100" height="100" src="/images/coin100.png" alt=""/>
                    <span>+50,000</span>
                </div>
            </div>
            <div className="winline-card-content">
                <p>{text || 'Привяжите аккаунт Winline, чтобы получить доступ ко всем заданиям'}</p>
                <div onClick={handleClick}>
                    <button className="link-account-button">Привязать аккаунт</button>
                </div>
            </div>
        </div>
    );
};

export default WinlineCard;
