import React from 'react';
import './EarningsInput.css';
import {Link} from "react-router-dom";

const EarningsInput = ({rewardsCount}) => {

    return (
        <div className="earnings-input">
            <div className="super-border">
                <div>
                Ваши выигрыши: <span>{rewardsCount}</span>
                </div>
                <Link to="/rewards">
                    <button className="earnings-view-button">Посмотреть</button>
                </Link>
            </div>
            <hr/>
        </div>
    );
};

export default EarningsInput;
