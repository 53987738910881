import React, {useCallback, useEffect, useState} from 'react';
import Layout from "../layout/Layout";
import LayoutWelcome from "../layout/LayoutWelcome";
import LayoutMain from '../layout/LayoutMain';
import Loader from '../loader/Loader';
import Welcome from "../../../user/components/welcome/Welcome";
import Navbar from "../navbar/Navbar";
import MainCounter from "../../../tap/components/mainCounter/MainCounter";
import NavigationBar from "../navigationBar/NavigationBar";
import {useDispatch} from 'react-redux';
import { setValue } from "../../../../store/userSlice";
import { getState} from "../../../../api/user";
import { useToast} from "../../../../contexts/ToastContext";
import { useLocation, useNavigate } from "react-router-dom";
import './Home.css'
import { getFriends } from "../../../../api/friends";
import { setFriends } from "../../../../store/friendsSlice";
import { setTasks } from "../../../../store/tasksSlice";
import { getTasks } from "../../../../api/tasks";
import {setGames } from "../../../../store/gamesSlice";
import { getGames } from "../../../../api/games";
import { setRewards } from "../../../../store/rewardsSlice";
import { getRewards } from "../../../../api/rewards";
import { setInitData } from "../../../../store/initDataSlice";
import {expandApp, useInitDataRaw, useInitDataState} from "../../../../utils/tg";
import {logError} from "../../../../utils/errorHandler";
import {getTeams} from "../../../../api/teams";
import {setTeams} from "../../../../store/teamsSlice";
import TeamSelection from "../../../teamSelection/TeamSelection";
import {getLeaders} from "../../../../api/leaders";
import {setLeaders} from "../../../../store/leadersSlice";

const Home = () => {

    const [loading, setLoading] = useState(true);
    const [gameStarted, setGameStarted] = useState(false);
    const [isFirstLogin, setIsFirstLogin] = useState(false);
    const [team, setTeam] = useState(false);
    const dispatch = useDispatch();
    const initDataRaw = useInitDataRaw();
    const initDataState = useInitDataState();
    const location = useLocation();
    const notify = useToast();
    const navigate = useNavigate();
    const handleSetUser = useCallback((user) => dispatch(setValue(user)), [dispatch]);
    const handleSetFriends = useCallback((friends) => dispatch(setFriends(friends)), [dispatch]);
    const handleSetTeams = useCallback((teams) => dispatch(setTeams(teams)), [dispatch]);
    const handleSetTasks = useCallback((tasks) => dispatch(setTasks(tasks)), [dispatch]);
    const handleSetGames = useCallback((games) => dispatch(setGames(games)), [dispatch]);
    const handleSetRewards = useCallback((rewards) => dispatch(setRewards(rewards)), [dispatch]);
    const handleSetInitData = useCallback((initData) => dispatch(setInitData(initData)), [dispatch]);
    const handleSetLeaders = useCallback((leaders) => dispatch(setLeaders(leaders)), [dispatch]);

    const fetchLeaders= useCallback(async () => {
        try {
            const response = await getLeaders(initDataRaw);
            handleSetLeaders(response.data);
        } catch (error) {
            logError(error.message, 'Home fetchLeaders', 0, 0, error);
            console.error("Error fetching leaders:", error);
        }
    }, [handleSetLeaders, initDataRaw]);

    const fetchTeams = useCallback(async () => {
        try {
            const response = await getTeams(initDataRaw);
            handleSetTeams(response.data);
        } catch (error) {
            logError(error.message, 'Home fetchTeams', 0, 0, error);
            console.error("Error fetching teams:", error);
        }
    }, [handleSetTeams, initDataRaw]);

    const fetchFriends = useCallback(async () => {
        try {
            const response = await getFriends(initDataRaw);
            handleSetFriends(response.data);
        } catch (error) {
            logError(error.message, 'Home fetchFriends', 0, 0, error);
            console.error("Error fetching friends:", error);
        }
    }, [handleSetFriends, initDataRaw]);

    const fetchTasks = useCallback(async () => {
        try {
            const response = await getTasks(initDataRaw);
            handleSetTasks(response.data);
        } catch (error) {
            logError(error.message, 'Home fetchTasks', 0, 0, error);
            console.error("Error fetching tasks:", error);
        }
    }, [handleSetTasks, initDataRaw]);

    const fetchGames = useCallback(async () => {
        try {
            const response = await getGames(initDataRaw);
            handleSetGames(response.data);
        } catch (error) {
            logError(error.message, 'Home fetchGames', 0, 0, error);
            console.error("Error fetching games:", error);
        }
    }, [handleSetGames, initDataRaw]);

    const fetchRewards = useCallback(async () => {
        try {
            const response = await getRewards(initDataRaw);
            handleSetRewards(response.data);
        } catch (error) {
            logError(error.message, 'Home fetchRewards', 0, 0, error);
            console.error("Error fetching rewards:", error);
        }
    }, [handleSetRewards, initDataRaw]);

    const initUser = useCallback(async () => {
        if (initDataState?.user) {

            handleSetInitData(initDataRaw);

            try {
                const response = await getState(initDataRaw);
                const {state} = response.data;

                handleSetUser(state);

                if (state.team){
                    setTeam(state.team);
                }

                if (state.is_first_login){
                    setIsFirstLogin(state.is_first_login);
                }

            } catch (error) {
                logError(error.message, 'Home initUser', 0, 0, error);
                console.error("Error fetching user state:", error);
            }
        }
    }, [ handleSetUser, initDataState, initDataRaw, handleSetInitData]);

    useEffect(() => {
        if (location.pathname === '/' && !isFirstLogin && team) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [location, isFirstLogin, team]);

    useEffect(() => {
        if (location.state?.toast_success) {
            notify(location.state.toast_success, 'success');
            navigate(location.pathname, {replace: true, state: {}});
        }

        if (location.state?.toast_error) {
            notify(location.state.toast_error, 'error');
            navigate(location.pathname, {replace: true, state: {}});
        }

    }, [location, notify, navigate]);


    useEffect(() => {
        expandApp();

        const loadUserData = async () => {

            const isFirstVisit = !sessionStorage.getItem('is_first_visit');

            if (isFirstVisit) {
                await Promise.all([initUser(), fetchTasks(), fetchFriends(), fetchGames(), fetchRewards(), fetchTeams(), fetchLeaders()]);
                sessionStorage.setItem('is_first_visit', 'false');
            } else {
                await Promise.all([initUser()]);

            }

        };

        loadUserData().then(() => {
            setLoading(false);
            sessionStorage.setItem('hasVisited', 'true');
        });

        const handleBeforeUnload = () => {
            sessionStorage.removeItem('hasVisited');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [initUser, fetchFriends, isFirstLogin, fetchTasks, fetchGames, fetchRewards, fetchTeams, fetchLeaders]);

    const handleStartGame = () => {
        setGameStarted(true);
        setIsFirstLogin(false);
    };

    if (loading && !sessionStorage.getItem('hasVisited')) {
        return (
            <Layout>
                <Loader/>
            </Layout>
        );
    }

    if (!gameStarted && isFirstLogin) {
        return (
            <LayoutWelcome>
                <Welcome onStartGame={handleStartGame}/>
            </LayoutWelcome>
        );
    }

    if (gameStarted && !team) {
        return (
            <LayoutWelcome>
                <TeamSelection />
            </LayoutWelcome>
        );
    }

    return (
        <LayoutMain addClass="no-bg">
            <Navbar/>


            <MainCounter/>


            <NavigationBar/>
        </LayoutMain>
    );
};

export default Home;