import {forwardRef, useImperativeHandle, useState} from "react";
import Confetti from "react-confetti";

const ConfettiController = forwardRef((props, ref) => {
    const [showConfetti, setShowConfetti] = useState(false);

    useImperativeHandle(ref, () => ({
        startConfetti() {
            setShowConfetti(true);
        }
    }));

    return <>{showConfetti && <Confetti style={{ pointerEvents: 'none' }} numberOfPieces={500} recycle={false} onConfettiComplete={confetti => {
        setShowConfetti(false);
        confetti.reset()
    }} />}</>;
});

export default ConfettiController;