import React, { useEffect, useState } from 'react';
import './ContestPrizes.css';
import getPercentageColor from "../../../app/components/PercentageColor";

const ContestPrizes = ({gamesData}) => {

    const [activeTab, setActiveTab] = useState('daily');
    const [timeLeft, setTimeLeft] = useState(null);

    const calculateTimeLeft = (date) => {
        const now = new Date();
        const currentTimeInMSK = new Date(now.toLocaleString('en-US', { timeZone: 'Europe/Moscow' }));
        const targetDate = new Date(date);

        const difference = targetDate - currentTimeInMSK;

        if (difference > 0) {
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / 1000 / 60) % 60);
            const seconds = Math.floor((difference / 1000) % 60);

            return {
                days,
                hours,
                minutes,
                seconds,
            };
        } else {
            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        }
    };

    useEffect(() => {
        if (gamesData) {
            const date = activeTab === 'daily' ? gamesData.daily.date : gamesData.monthly.date;

            const updateTimer = () => {
                setTimeLeft(calculateTimeLeft(date));
            };

            updateTimer();
            const timerInterval = setInterval(updateTimer, 1000);

            return () => clearInterval(timerInterval);
        }
    }, [gamesData, activeTab]);

    const renderPrizes = (prizes) => {
        return Object.keys(prizes).map((amount, index) => {
            const chance = prizes[amount];

            return (
                <div className="prize-card super-border" key={index}>
                    <div className="prize-info">
                        <div className="prize-title">
                            <div className="title">Фрибет</div>
                            {new Intl.NumberFormat('ru-RU').format(amount)}
                        </div>
                        <img width="63" height="62" src={'/images/prizes/' +amount + '.png'} alt="Фишка" className="prize-icon"/>
                    </div>
                    <div className="prize-chance">
                        <div
                            className="chance-bar"
                            style={{
                                '--bar-color': getPercentageColor(chance),
                                '--bar-width': `${chance}%`
                            }}
                        ></div>
                        <p><span className="chance">шанс:</span> {chance.toFixed(2)}%</p>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="contest-prize">
            <div className="contest-tabs">
                <button
                    className={`contest-tab super-border ${activeTab === 'daily' ? 'active' : ''}`}
                    onClick={() => setActiveTab('daily')}
                >
                    ЕЖЕДНЕВНЫЙ
                </button>
                <button
                    className={`contest-tab super-border ${activeTab === 'monthly' ? 'active' : ''}`}
                    onClick={() => setActiveTab('monthly')}
                >
                    ЕЖЕМЕСЯЧНЫЙ
                </button>
            </div>

            <div className="contest-timer">
                {timeLeft ? (
                    <span>
                        {timeLeft.days > 0 ? `${timeLeft.days}д. ` : ''}
                        {`${timeLeft.hours}ч. ${timeLeft.minutes}м`}
                    </span>
                ) : (
                    <span>Таймер истек</span>
                )}
                <p className="contest-timer-text">до проведения розыгрыша</p>
            </div>

            <div className="contest-prizes">
                {activeTab === 'daily' && renderPrizes(gamesData.daily.prizes)}
                {activeTab === 'monthly' && renderPrizes(gamesData.monthly.prizes)}
            </div>
            <button className="try-luck-button disabled">Испытать удачу</button>
        </div>
    );
};

export default ContestPrizes;
