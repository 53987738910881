import React, {useCallback, useEffect, useState} from 'react';
import './TeamSelection.css';
import TeamItem from "./components/TeamItem";
import {logError} from "../../utils/errorHandler";
import {getTeams, setTeam} from "../../api/teams";
import {getState} from "../../api/user";
import {setValue} from "../../store/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {useInitDataRaw} from "../../utils/tg";
import {setTeams} from "../../store/teamsSlice";

const TeamSelection = () => {

    const teamsData = useSelector((state) => state.teams.value);
    const initDataRaw = useInitDataRaw();
    const [selectedTeam, setSelectedTeam] = useState(null);
    const dispatch = useDispatch();
    const handleSetUser = useCallback((user) => dispatch(setValue(user)), [dispatch]);
    const handleSetTeams = useCallback((teams) => dispatch(setTeams(teams)), [dispatch]);

    const handleSelectTeam = (team) => {
        setSelectedTeam(team);
    };

    useEffect(() => {
        const fetchTeams = async () => {
            try {
                const response = await getTeams(initDataRaw);
                handleSetTeams(response.data);
                setSelectedTeam(response.data[0])
            } catch (error) {
                logError(error.message, 'TeamSelection fetchTeams', 0, 0, error);
                console.error("Error fetching teams:", error);
            }
        };
        fetchTeams();
    }, [handleSetTeams, initDataRaw]);

    const fetchUserState = async () => {
        try {
            const response = await getState(initDataRaw);
            const {state} = response.data;
            handleSetUser(state);
            window.location.reload();
        } catch (error) {
            logError(error.message, 'TeamSelection fetchUserState', 0, 0, error);
        }
    };

    const handleStart = async () => {
        if (selectedTeam) {
            try {
                const response = await setTeam(initDataRaw, selectedTeam.id);
                if (response.data.success) {
                    fetchUserState();
                }
            } catch (error) {
                logError(error.message, 'TeamSelection setTeam', 0, 0, error);
            }
        }
    };

    return (
        <div className="team-selection">
            <h2>Выбери команду</h2>
            <p className="alt-font">Зарабатывай монеты в общий фонд и соревнуйся с другими командами за ТОП-1</p>

            <div className="team-list">
                {teamsData.map((team) => (
                    <TeamItem
                        key={team.id}
                        team={team}
                        isSelected={selectedTeam && team.id === selectedTeam.id}
                        onSelect={() => handleSelectTeam(team)}
                    />
                ))}
            </div>

            <button
                className="start-button"
                disabled={!selectedTeam}
                onClick={handleStart}
            >
                Начать
            </button>
        </div>
    );
};

export default TeamSelection;
