import React from 'react';
import './StatsSection.css';
import {useSelector} from "react-redux";
import getPercentageColor from "../../../app/components/PercentageColor";

const StatsSection = () => {
    const user = useSelector((state) => state.user.value);
    const coinsDaily = user?.coins_daily;
    const coinsMonthly = user?.coins_monthly;
    const chanceDaily = user?.daily_chance;
    const chanceMonthly = user?.monthly_chance;



    return (
        <div className="stats-section super-border">
            <div className="stat-item">
                <div className="stat-section">
                    <p className="stat-label">Earn today</p>
                    <div className="stat-content">
                        <img width="100" height="100" src="/images/coin100.png" alt="WCoin Icon" className="stat-icon"/>
                        <span className="stat-value">{ coinsDaily.toLocaleString('en-US') }</span>
                    </div>
                </div>
                <div className="divider"></div>
            </div>

            <div className="stat-item">
                <div className="stat-section">
                    <p className="stat-label">Earn month</p>
                    <div className="stat-content">
                        <img width="100" height="100" src="/images/coin100.png" alt="WCoin Icon" className="stat-icon"/>
                        <span className="stat-value">{coinsMonthly.toLocaleString('en-US')}</span>
                    </div>
                </div>
                <div className="divider"></div>
            </div>

            <div className="stat-item">
                <div className="stat-section">
                    <p className="stat-label">Win chance day</p>
                    <div className="stat-content">
                        <span className="stat-value" style={{ color: getPercentageColor(chanceDaily) }}>{ chanceDaily }%</span>

                    </div>
                </div>
                <div className="divider"></div>
            </div>

            <div className="stat-item">
                <div className="stat-section">
                    <p className="stat-label">Win chance month</p>
                    <div className="stat-content">
                        <span className="stat-value" style={{ color: getPercentageColor(chanceMonthly) }}>{ chanceMonthly }%</span>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatsSection;
